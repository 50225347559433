<template>
  <div id="view-article-detail" class="">
    <div class="media left">
      <div class="media-left">
        <figure class="image is-64x64">
          <img :src="iconForType(article)" alt="Image" />
        </figure>
      </div>
      <div class="media-content">
        <div class="content">
          <div class="title is-5">{{ article.title }}</div>
          <span v-html="compileMarkdown(article.body)"></span>
        </div>
        <div class="level is-mobile">
          <div class="level-left">
            <small class="level-item"
              >{{ article.byLine != "," ? article.byLine : "System" }} @
              {{ formatDate(article.posted) }}</small
            >
          </div>
          <div class="level-right">
            <b-taglist v-if="article != null" class="level-item">
              <b-tag
                type="is-light"
                v-for="item in article.tags"
                :key="article.tags.indexOf(item)"
                >{{ item }}</b-tag
              >
            </b-taglist>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/Druware.Web.Shared/api/api";
import Marked from "marked";

export default {
  name: "view.article",
  data: function() {
    return {
      session: {},
      article: {}
    };
  },
  methods: {
    formatDate(str) {
      var d = new Date(str);
      return (
        d.toLocaleDateString("en-US") + "  " + d.toLocaleTimeString("en-US")
      );
    },
    compileMarkdown(str) {
      return str == null ? null : Marked(str.replaceAll("\\n", "\n"));
    },
    iconForType(article) {
      return article == null ||
        article.type == null ||
        article.type.icon == null
        ? "/icon/article.gif"
        : "/api/asset/content/" + article.type.icon.fileName;
    }
  },
  created() {
    // this.session = this.$attrs.session;
    API.get(
      API.url.news,
      this,
      this.$route.params.permalink,
      function(obj, data) {
        obj.article = data;
        window.document.title =
          "Druware - " +
          (obj.article && obj.article.title ? obj.article.title : "");
      },
      function(obj, err) {
        obj.$buefy.toast.open({
          duration: 5000,
          message: err,
          position: "is-bottom",
          type: "is-danger"
        });
      }
    );
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#view-article-detail {
  .left {
    text-align: left;
  }

  .icon-count {
    width: 48px;
  }
}
</style>
